import React from "react";
import theme from "theme";
import { Theme, Link, Image, Box, Section, Text, Icon, LinkBox } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, Menu } from "@quarkly/components";
import { MdKeyboardArrowDown, MdEmail, MdPhone, MdKeyboardArrowUp } from "react-icons/md";
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				BY MAR&Eacute;
			</title>
			<meta name={"description"} content={"By Mare"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/readme/cra/favicon-32x32.ico"} type={"image/x-icon"} />
			<script src="https://apps.elfsight.com/p/platform.js" defer type="text/javascript" />
		</Helmet>
		<Section height="70px" min-height="70px" padding="0 0 0px 0" background="Rgba(14,70,78)">
			<Override slot="SectionContent" flex="1 1 0%" />
			<Box
				display="flex"
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
				md-flex-direction="column"
				id="top"
				className="top"
			>
				<Image src="images/logo_top.png" display="block" />
				<Menu
					display="flex"
					justify-content="center"
					font="--base"
					font-weight="700"
					md-flex-direction="column"
					md-align-items="center"
				>
					Menu Here
					<Override slot="link" text-decoration="none" color="--dark" padding="6px 12px" />
					<Override slot="link-active" color="Rgba(14,70,78)" />
					<Override slot="item" padding="6px" />
				</Menu>
			</Box>
		</Section>
		<Section sm-padding="40px 0" padding="0 0 0px 0" id="hero" className="hero">
			<Override slot="SectionContent" align-items="center" />
			<Image src="images/hero-02.png" display="block" flex="0 1 auto" position="static" />
		</Section>
		<Section padding="80px 0" sm-padding="40px 0" id="about" className="about">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 20px 0">
				About Us
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				At By Mare, we want to turn your vision from concept to reality whilst working within your budget. With over two decades in the industry, we can ensure you find the design solution you dream of. Let us help you connect with one of our designers who meets your needs and is available to see you, online or in person.
			</Text>
		
			<Box min-width="100px" min-height="100px" width="inherit">
				<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
					
					<div class="elfsight-app-89ef72e0-c4a5-44df-a764-34f72d37d6c7"></div>
				
				</Text>
			</Box>

			<Link href="#services" color="#000000">
				<Icon
					category="md"
					icon={MdKeyboardArrowDown}
					size="64px"
					border-style="solid"
					border-radius="50%"
					border-color="Rgba(14,70,78) "
					color="Rgba(14,70,78) "
				/>
			</Link>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0" id="services" className="services">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 20px 0">
				Our Services
			</Text>
			<Box
				display="grid"
				lg-flex-wrap="wrap"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="36px 4%"
				lg-grid-template-columns="repeat(2, 1fr)"
				sm-grid-template-columns="1fr"
				sm-grid-gap="12px"
				margin="20px 0px 20px 0px"
			>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Project Management
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Are you renovating or about to owner build? Allow our team to organise everything and be your 'eyes and ears' on site. We assure that your project will run smoothly and on schedule. Our team will provide photos of the project to keep you updated regularly & involved. Picking up and dropping off materials when trades need them? Consider it done. We organise all the trades. Leave the hard work to us!
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 30px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Consulting
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Providing which package deal you choose our consulting team will give you a direct and honest opinion. We want your project to have a consistent coherent look throughout. Samples are provided with all package deals. Let us do the research on the best price possible available for your product, this way we are able to provide you with a very competitive quote.
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-margin="0px 0px 30px 0px" sm-width="100%">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Design
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Our design team will guide you through the selection process by providing mood boards and colour samples for all hard furnishings. This includes tiles, stone, paint, carpets, floorboards, appliances and bathroom ware. It can be a daunting and stressful process, however with our experienced team beside you you every step of the way, allow us lead you in the right direction
					</Text>
				</Box>
				<Box display="flex" flex-direction="column" sm-width="100%" sm-margin="0px 0px 0px 0px">
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Trade List
					</Text>
					<Text margin="0px 0px 0px 0px" color="--greyD2" font="--base" lg-text-align="left">
						Our list of trusted trades have been hand- picked carefully over the years by our team. We can ensure they will provide an excellent service and finished product. Direct select is happy to provide you contact details and quotes from all our trades. For access to our list, there will be a fee of $95 for every trade.
					</Text>
				</Box>
			</Box>
			<Link href="#packages" color="#000000" >
				<Icon
					category="md"
					icon={MdKeyboardArrowDown}
					size="64px"
					border-radius="50%"
					border-style="solid"
					border-color="Rgba(14,70,78) "
					color="Rgba(14,70,78) "
				/>
			</Link>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0" id="packages" className="packages">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 20px 0">
				Packages
			</Text>
			<Box min-width="100px" min-height="100px">
				<Box
					display="grid"
					flex-wrap="wrap"
					width="100%"
					align-items="center"
					justify-content="center"
					grid-template-columns="repeat(3, 1fr)"
					grid-gap="30px"
					md-grid-template-columns="1fr"
					margin="20px 0px 20px 0px"
				>
					<Box
						width="100%"
						display="flex"
						padding="7px 7px 40px 7px"
						border-radius="24px"
						align-items="center"
						flex-direction="column"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						justify-content="center"
					>
						<Box
							display="flex"
							flex-direction="column"
							padding="0px 30px 0px 30px"
							align-items="flex-start"
							justify-content="flex-start"
						>
							<Text margin="0px 0px 8px 0px" font="--headline3">
								Standard Package
							</Text>
							<Image src="images/gold_line.png" display="block" width="300px" />
							<Text margin="0px 0px 16px 0px" text-align="center" font="--lead">
								$ 360
							</Text>
							<Text margin="0px 0px 48px 0px" color="--darkL2" font="--lead">
								- 30 mins over phone consult
								<br />
								- product Quote
								<br />
								- Box of samples
								<br />
								&nbsp;
							</Text>
						</Box>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="7px 7px 40px 7px"
						border-radius="24px"
						align-items="center"
						flex-direction="column"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						justify-content="center"
						position="relative"
					>
						<Box
							display="flex"
							flex-direction="column"
							padding="0px 30px 0px 30px"
							align-items="flex-start"
							justify-content="flex-start"
						>
							<Text margin="0px 0px 8px 0px" font="--headline3">
								Delux Package
							</Text>
							<Image src="images/gold_line.png" display="block" width="300px" />
							<Text margin="0px 0px 16px 0px" text-align="center" font="--lead">
								$ 700
							</Text>
							<Text margin="0px 0px 48px 0px" color="--darkL2" font="--lead">
								- 1 hour online consultation
								<br />
								- Product Quotes
								<br />
								- Full samples
								<br />
								- 1 onsite selection
							</Text>
						</Box>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="7px 7px 40px 7px"
						border-radius="24px"
						align-items="center"
						flex-direction="column"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						justify-content="center"
					>
						<Box
							display="flex"
							flex-direction="column"
							padding="0px 30px 0px 30px"
							align-items="flex-start"
							justify-content="flex-start"
						>
							<Text margin="0px 0px 8px 0px" font="--headline3">
								Luxury Package
							</Text>
							<Image src="images/gold_line.png" display="block" width="300px" />
							<Text margin="0px 0px 16px 0px" text-align="center" font="--lead">
								$ 1000
							</Text>
							<Text margin="0px 0px 48px 0px" color="--darkL2" font="--lead">
								- 2 hour face to face consultation
								<br />
								- Product Quotes
								<br />
								- Full samples shipped to you
								<br />
								- 2 on site selections
							</Text>
						</Box>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="7px 7px 40px 7px"
						border-radius="24px"
						align-items="center"
						flex-direction="column"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						justify-content="center"
					>
						<Box
							display="flex"
							flex-direction="column"
							padding="0px 30px 0px 30px"
							align-items="flex-start"
							justify-content="flex-start"
						>
							<Text margin="0px 0px 8px 0px" font="--headline3">
								Project Management
							</Text>
							<Image src="images/gold_line.png" display="block" width="300px" />
							<Text margin="0px 0px 16px 0px" text-align="center" font="--lead">
								$ 55 p/h
							</Text>
							<Text margin="0px 0px 48px 0px" color="--darkL2" font="--lead">
								- Expert Project Management across your renovation!
							</Text>
						</Box>
					</Box>
					<Box
						width="100%"
						display="flex"
						padding="7px 7px 40px 7px"
						border-radius="24px"
						align-items="center"
						flex-direction="column"
						border-width="1px"
						border-style="solid"
						border-color="--color-lightD2"
						justify-content="center"
					>
						<Box
							display="flex"
							flex-direction="column"
							padding="0px 30px 0px 30px"
							align-items="flex-start"
							justify-content="flex-start"
						>
							<Text margin="0px 0px 8px 0px" font="--headline3">
								Trades Access
							</Text>
							<Image src="images/gold_line.png" display="block" width="300px" />
							<Text margin="0px 0px 16px 0px" text-align="center" font="--lead">
								$ 100
							</Text>
							<Text margin="0px 0px 48px 0px" color="--darkL2" font="--lead">
								- Access to trades from our trades list
								<br />
								&nbsp;
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
			<Link href="#contact" color="#000000">
				<Icon
					category="md"
					icon={MdKeyboardArrowDown}
					size="64px"
					border-style="solid"
					border-radius="50%"
					border-color="Rgba(14,70,78) "
					color="Rgba(14,70,78) "
				/>
			</Link>
		</Section>
		<Section padding="80px 0" sm-padding="40px 0" id="contact" className="contact">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 20px 0">
				Contact Us
			</Text>
			<Box min-width="100px" min-height="100px">
				<Box
					display="grid"
					grid-template-columns="repeat(3, 1fr)"
					grid-gap="32px"
					lg-grid-template-columns="repeat(2, 1fr)"
					sm-grid-template-columns="1fr"
					width="100%"
					margin="20px 0px 20px 0px"
				>
					<LinkBox
						display="flex"
						md-flex-direction="column"
						md-align-items="center"
						md-justify-content="center"
						flex-wrap="wrap"
						sm-width="100%"
						grid-column="1 / span 1"
						sm-align-self="auto"
						sm-grid-column="auto"
						justify-content="flex-start"
						color="#000000"
					>
						<Box
							width="100%"
							padding="16px 12px 16px 12px"
							display="flex"
							align-items="flex-start"
							justify-content="flex-start"
							md-width="100%"
							md-border-width="0px"
							flex-direction="column"
						>
							<Box sm-display="flex" sm-flex-direction="column" sm-align-items="flex-start">
								<Box min-width="100px" min-height="100px">
									<Text margin="0px 0px 0px 0px" font="--lead">
										For more information on how we can help you.  Please contact us.
									</Text>
								</Box>
								<Box
									padding="0 0 0 64px"
									sm-padding="64px 0 0 0"
									margin="64px 0 0 0"
									max-width="360px"
									position="relative"
									sm-display="flex"
									sm-flex-direction="column"
									sm-width="100%"
									sm-max-width="100%"
								>
									<Icon
										position="absolute"
										size="48px"
										top="0"
										left="0"
										category="md"
										color="Rgba(14,70,78)"
										icon={MdEmail}
									/>
									<Text as="p" margin="6px 0" font="--headline3">
										<Link
											href="mailto:info@bymare.com.au"
											text-decoration="none"
											hover-text-decoration="underline"
											color="#04080C"
											sm-width="100%"
										>
											info@bymare.com.au
										</Link>
									</Text>
								</Box>
								<Box
									padding="0 0 0 64px"
									margin="64px 0 0 0"
									max-width="360px"
									position="relative"
									sm-display="flex"
									sm-flex-direction="column"
									sm-width="100%"
									sm-max-width="100%"
									sm-padding="64px 0 0 0"
								>
									<Icon
										position="absolute"
										size="48px"
										top="0"
										left="0"
										category="md"
										icon={MdPhone}
										sm-display="block"
										color="Rgba(14,70,78)"
									/>
									<Text as="p" margin="6px 0" font="--headline3" sm-width="100%">
										+61 (0) 401 515 651
									</Text>
								</Box>
								<Box
									padding="0 0 0 64px"
									sm-padding="64px 0 0 0"
									margin="64px 0 0 0"
									max-width="360px"
									position="relative"
									sm-display="flex"
									sm-flex-direction="column"
									sm-width="100%"
									sm-max-width="100%"
								>
									<Icon
										position="absolute"
										size="48px"
										top="0"
										left="0"
										category="fa"
										icon={FaInstagram}
										color="Rgba(14,70,78)"
									/>
									<Text as="p" margin="6px 0" font="--headline3">
										<Link
											href="https://www.instagram.com/bymare__"
											text-decoration="none"
											hover-text-decoration="underline"
											color="#04080C"
											sm-width="100%"
										>
											bymare__
										</Link>
									</Text>
								</Box>
								{/*}
								<Box
									padding="0 0 0 64px"
									sm-padding="0"
									margin="48px 0"
									max-width="360px"
									position="relative"
									display="flex"
								>
									<Icon
										category="fa"
										icon={FaFacebookF}
										width="48px"
										height="48px"
										size="24px"
										margin-right="16px"
										background="Rgba(14,70,78)"
										border-radius="50%"
										color="Rgba(255,255,255)"
									/>
									<Icon
										category="fa"
										icon={FaTwitter}
										width="48px"
										height="48px"
										size="24px"
										margin-right="16px"
										background="Rgba(14,70,78)"
										border-radius="50%"
										color="Rgba(255,255,255)"
									/>
									<Icon
										category="fa"
										icon={FaLinkedinIn}
										width="48px"
										height="48px"
										size="24px"
										margin-right="16px"
										background="Rgba(14,70,78)"
										border-radius="50%"
										color="Rgba(255,255,255)"
									/>
								</Box>
								*/}
							</Box>
						</Box>
					</LinkBox>
					<LinkBox
						display="flex"
						md-flex-direction="column"
						md-align-items="center"
						md-justify-content="center"
						flex-wrap="wrap"
						sm-width="100%"
						grid-column="2 / span 2"
						lg-grid-column="2 / span 1"
						sm-align-self="auto"
						sm-grid-column="auto"
						justify-content="flex-start"
					>
						<Box
							width="100%"
							align-items="flex-start"
							display="flex"
							justify-content="flex-start"
							md-width="100%"
							md-margin="0px 0px 0px 0px"
							lg-width="100%"
						>
							<Image
								src="images/contact.png"
								border-radius="24px"
								max-width="100%"
								width="100%"
								object-fit="cover"
								lg-max-height="392px"
								object-position="50% 50%"
								sm-max-height="213px"
								height="522px"
							/>
						</Box>
					</LinkBox>
				</Box>
			</Box>
			<Link href="#top" color="#000000">
				<Icon
					category="md"
					icon={MdKeyboardArrowUp}
					size="64px"
					border-style="solid"
					border-radius="50%"
					border-color="Rgba(14,70,78) "
					color="Rgba(14,70,78) "
				/>
			</Link>
		</Section>
		<Section background-color="--dark" text-align="center" padding="32px 0" background="Rgba(14,70,78)">
			<Menu
				display="flex"
				justify-content="center"
				font="--lead"
				font-weight="700"
				margin="-6px 0 16px"
				md-flex-direction="column"
				md-align-items="center"
			>
				<Override slot="link" text-decoration="none" color="--light" padding="6px 12px">
					index
				</Override>
				<Override slot="link-active" color="Rgba(14,70,78)" />
				<Override slot="item" padding="6px 0px" />
			</Menu>
			<Text margin="0px 0px 0px 0px" color="#6e6e6e">
				(c) 2023 www.dxp.com.au
			</Text>
		</Section>

		{/*}
		<Link
			font={"--capture"}
			font-size={"10px"}
			position={"fixed"}
			bottom={"12px"}
			right={"12px"}
			z-index={"4"}
			border-radius={"4px"}
			padding={"5px 12px 4px"}
			background-color={"--dark"}
			opacity={"0.6"}
			hover-opacity={"1"}
			color={"--light"}
			cursor={"pointer"}
			transition={"--opacityOut"}
			quarkly-title={"Badge"}
			text-decoration-line={"initial"}
			href={"https://quarkly.io/"}
			target={"_blank"}
		>
			Made on Quarkly
		</Link>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"640e68894b38c40020026f7a"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
		*/}

	</Theme>;
});