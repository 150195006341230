export default {
	"pages": {
		"root": {
			"id": "root",
			"pageUrl": "root",
			"name": "root",
			"children": [
				"640e68894b38c40020026f81",
				"640e68894b38c40020026f84"
			]
		},
		"640e68894b38c40020026f81": {
			"id": "640e68894b38c40020026f81",
			"name": "404",
			"pageUrl": "404"
		},
		"640e68894b38c40020026f84": {
			"id": "640e68894b38c40020026f84",
			"name": "index",
			"pageUrl": "index"
		}
	},
	"mode": "production",
	"projectType": "create-react-app",
	"site": {
		"styles": {},
		"seo": {}
	}
}